import CardsMouseFollow from "../../components/CardsMouseFollow/CardsMouseFollow";
import Images from "../../media/Images";
import { CardData } from "../../utils/interfaces";

const ComponentsExamples: React.FC = () => {
  const DATA: CardData = {
    author: "SoulSowl",
    image: Images.allSkies,
    target: "_self",
    title: "All Skies",
    url: "AllSkies",
  };

  return (
    <div>
      <div>
        <h1 style={{ display: "flex", justifyContent: "center" }}>
          CardsMouseFollow
        </h1>
        <CardsMouseFollow
          author={DATA.author}
          image={DATA.image}
          target={DATA.target}
          title={DATA.title}
          url={DATA.url}
        />
      </div>
    </div>
  );
};

export default ComponentsExamples;
