import { useEffect } from "react";
import { PageAnalytics } from "../../utils/types";

const Info: React.FC<PageAnalytics> = (props: PageAnalytics) => {
  const { fireKit } = props;

  useEffect(() => {
    fireKit.logEvent(fireKit.analytics, "view_info");
  }, [fireKit]);

  return (
    <div>
      <div>
        <h1
          style={{ display: "flex", justifyContent: "center", color: "white" }}
        >
          WIP
        </h1>
      </div>
    </div>
  );
};

export default Info;
