import { useRef } from "react";
import "./Card.css";
import { Link } from "react-router-dom";

const CardsMouseFollow = ({ author, title, target, image, url }: any) => {
  // const [xRotation, setXRotation] = useState(0);
  // const [yRotation, setYRotation] = useState(0);
  const cardRef = useRef(null);
  const imgRef = useRef(null);
  const titleRef = useRef(null);
  const descRef = useRef(null);
  // const sizesboxRef = useRef(null);
  const purchaseRef = useRef(null);

  // function handleMouseMove(event) {
  //   const card = cardRef.current;
  //   const { offsetWidth: width, offsetHeight: height } = card;
  //   const { clientX, clientY } = event;
  //   const x = clientX - card.offsetLeft - width / 2;
  //   const y = clientY - card.offsetTop - height / 2;
  //   var mult = 40;
  //   setXRotation((y / height) * mult);
  //   setYRotation((x / width) * mult);
  // }
  // function handleMouseEnter() {
  //   const img = imgRef.current;
  //   const title = titleRef.current;
  //   const sizesBox = sizesboxRef.current;
  //   const purchase = purchaseRef.current;
  //   const desc = descRef.current;
  //   title.style.transform = "translateZ(150px)";
  //   img.style.transform = "translateZ(100px) rotateZ(-45deg)";
  //   sizesBox.style.transform = "translateZ(100px)";
  //   purchase.style.transform = "translateZ(75px)";
  //   desc.style.transform = "translateZ(75px)";
  // }
  // function handleMouseLeave() {
  //   setXRotation(0);
  //   setYRotation(0);

  //   const img = imgRef.current;
  //   const title = titleRef.current;
  //   const sizesBox = sizesboxRef.current;
  //   const purchase = purchaseRef.current;
  //   title.style.transform = "translateZ(0px)";
  //   img.style.transform = "translateZ(0px) rotateZ(0deg)";
  //   sizesBox.style.transform = "translateZ(0px)";
  //   purchase.style.transform = "translateZ(0px)";
  // }

  return (
    <Link
      to={{
        pathname: url,
      }}
      target={target}
      style={{ textDecoration: "none", marginBottom: "10px" }}
    >
      <div
        className="card"
        ref={cardRef}
        // style={{
        //   transform: `rotateX(${xRotation}deg) rotateY(${yRotation}deg)`,
        // }}
        // onMouseMove={handleMouseMove}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
      >
        <img ref={imgRef} src={image} alt="Story-Cover" className="story-img" />
        <div>
          <h3 className="obra" ref={titleRef}>
            Obra
          </h3>
          <h1 className="obraName" ref={titleRef}>
            {title}
          </h1>
        </div>
        <p className="creator" ref={descRef}>
          Creador: {author}
        </p>

        <div className="button-box" ref={purchaseRef}>
          <button className="purchase">Ver Obra</button>
        </div>
      </div>
    </Link>
  );
};

export default CardsMouseFollow;
