import Cards from "./Cards";
import Images from "../../media/Images";
import { CardData } from "../../utils/interfaces";

const DATA: CardData[] = [
  {
    author: "SoulSowl",
    image: Images.allSkies,
    target: "_self",
    title: "All Skies",
    url: "/AllSkies",
  },
  {
    author: "SoulSowl",
    image: Images.sufferInSilence,
    target: "_blank",
    title: "Suffer In Silence",
    url: "https://suffer.thecomicseries.com/",
  },
  // {
  //   author: "SoulSowl",
  //   image: Images.components,
  //   target: "_blank",
  //   title: "Components",
  //   url: "/Components",
  // },
];

const CardsPage: React.FC = () => {
  return (
    <div>
      <Cards DATA={DATA} />
    </div>
  );
};

export default CardsPage;
