import * as React from "react";
import "./Pages.css";
import { PageProps } from "../../utils/types";
import { useRef } from "react";
import { useIsVisible } from "react-is-visible";

const styling = (isVisible: boolean) => ({
  boxShadow: isVisible ? "0px 0px 30px black" : "0px 0px 30px turquoise",
  transition: "all 2s ease",
});

const SomeComponent = ({ onVisible, ...props }: any) => {
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);

  React.useEffect(() => {
    onVisible(isVisible);
  }, [onVisible, isVisible]);

  return <div ref={nodeRef} style={styling(isVisible)} {...props} />;
};

const Pages: React.FC<PageProps> = (props: PageProps) => {
  const { CHAPTER, fireKit } = props;
  const windowSize = React.useRef([window.innerWidth, window.innerHeight]);

  const registerEvent = (isVisible: boolean) => {
    if (isVisible) {
      fireKit.logEvent(fireKit.analytics, "view_page");
    }
  };

  return (
    <div
      className={
        windowSize.current[0] > 1023
          ? "containerLargeScreen"
          : "containerSmallScreen"
      }
    >
      {CHAPTER.map((data) => {
        return (
          <SomeComponent
            onVisible={(isVisible: boolean) => {
              registerEvent(isVisible);
            }}
          >
            <img src={data} alt="page" className="page" />
          </SomeComponent>
        );
      })}
    </div>
  );
};

export default Pages;
