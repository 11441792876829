import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CardsPage from "./views/Card/CardPage";
import ComponentsExamples from "./views/ComponentsExamples/ComponentsExamples";
import AllSkiesPage from "./views/AllSkies/AllSkiesPage";
import Headroom from "react-headroom";
import DrawerAppBar from "./components/NavBar";
import Info from "./views/Info/Info";
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import firebaseConfig from "./firebase-config";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Headroom>
          <DrawerAppBar />
        </Headroom>
        <Switch>
          <Route
            exact
            path={["/", "/front-portfolio", "/front-portfolio/Index"]}
          >
            <CardsPage />
          </Route>

          <Route exact path="/AllSkies">
            <AllSkiesPage fireKit={{ analytics, logEvent }} />
          </Route>
          <Route exact path="/Components">
            <ComponentsExamples />
          </Route>
          <Route exact path="/Info">
            <Info fireKit={{ analytics, logEvent }} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
